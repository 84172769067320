import { environment } from 'src/environments/environment';
import type { MenuNode } from './MenuNode';

export const menuPgeLegado: MenuNode = {
  id: 1,
  nome: 'Área Restrita antiga',
  descricao: 'Área Restrita antiga',
  rota: environment.apiAreaRestritaLegado,
  url: 'S',
  ordem: 1,
  filhos: [],
  materialIcon: 'home'
};
