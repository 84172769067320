import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TituloRotaResolver implements Resolve<string> {
  public resolve(route: ActivatedRouteSnapshot): string {
    const path = route.pathFromRoot.map((r) => r.url.map((segment) => segment.path).join('/')).join('/');
    console.log('path', path, route.pathFromRoot);
    const tituloPadrao = 'Página sem título';
    if (path.startsWith('/menu/')) {
      const opcao = route.paramMap.get('opcao');
      console.log('opcao', opcao);

      if (opcao == null) return tituloPadrao;
      switch (opcao) {
        case 'INSTITUCIONAL':
          return 'Institucional';
        case 'AREASATUACAO':
          return 'Áreas de Atuação';
        case 'CORREGEDORIA':
          return 'Corrgedoria';
        case 'CONSELHO':
          return 'Conselho';
        case 'OUVIDORIA':
          return 'Ouvidoria';
        default:
          return tituloPadrao;
      }
    }

    return tituloPadrao;
  }
}
