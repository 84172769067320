/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import type { EnvironmentProviders } from '@angular/core';
import { definePreset } from '@primeng/themes';

const temaConfig = {
  semantic: {
    primary: {
      50: '#fdeaea',
      100: '#fbd5d5',
      200: '#f7baba',
      300: '#f19f9f',
      400: '#eb8484',
      500: '#e56969',
      600: '#d04d4d',
      700: '#b63535',
      800: '#8e2a2a',
      900: '#862929',
      950: '#4d1515'
    },
    colorScheme: {
      light: {
        primary: {
          color: '#862929',
          inverseColor: '#ffffff',
          hoverColor: '#b63535',
          activeColor: '#d04d4d'
        },
        highlight: {
          background: '#862929',
          focusBackground: '#b63535',
          color: '#ffffff',
          focusColor: '#ffffff'
        }
      },
      dark: {
        primary: {
          color: '#fdeaea',
          inverseColor: '#862929',
          hoverColor: '#fbd5d5',
          activeColor: '#f7baba'
        },
        highlight: {
          background: 'rgba(250, 250, 250, .16)',
          focusBackground: 'rgba(250, 250, 250, .24)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)'
        }
      }
    }
  }
};

const pgePreset = definePreset(Aura, temaConfig);

export function providePrimeNGConfig(): EnvironmentProviders {
  return providePrimeNG({
    theme: {
      preset: pgePreset,
      options: {
        darkModeSelector: false
      }
    },
    translation: {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
      today: 'Hoje',
      clear: 'Limpar'
    }
  });
}
