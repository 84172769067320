import type { MenuNode } from './MenuNode';

export const menuPgeHome: MenuNode = {
  id: 0,
  nome: 'Home',
  descricao: 'Home - Início da página',
  rota: 'home-site-pge',
  url: 'S',
  ordem: 0,
  filhos: [],
  materialIcon: 'home'
};
