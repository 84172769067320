import { NgClass } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostListener, inject, signal } from '@angular/core';
import type { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './area-aberta/core/layout/footer/footer.component';
import { HeaderComponent } from './area-aberta/core/layout/header/header.component';
import { MenuComponent } from './area-aberta/core/layout/menu/menu.component';
import { SearchResultsComponent } from './area-aberta/core/layout/menu/search-results/search-results.component';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [HeaderComponent, MenuComponent, SearchResultsComponent, FooterComponent, MatSidenavModule, MatIcon, RouterOutlet, NgClass, ToastModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private readonly route = inject(Router);
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly dialog = inject(MatDialog);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);

  private readonly rotasSemCabecalho = ['/painel-area-externa', '/login', '/esqueci-senha', '/alterar-senha', '/area-restrita'];

  public visibilidade: boolean = false;

  public readonly buscar = signal('');

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    if (window.innerWidth > 480) {
      this.dialog.closeAll();
    }
  }

  public ngOnInit(): void {
    this.googleAnalyticsService.inicializar();
    this.activeRoute.queryParams.subscribe((params) => {
      this.buscar.set(params['buscar'] as string);
    });
  }

  public naoFomularioLogin(): boolean {
    if (this.rotasSemCabecalho.includes(this.route.url) || this.route.url.includes('/area-restrita')) {
      return false;
    }
    return true;
  }

  public abrirMenu(): void {
    const config: MatDialogConfig = {
      height: '100%',
      width: 'fit-content',
      position: { left: '0' },
      data: { buscar: this.buscar() }
    };
    this.dialog.open(MenuComponent, config);
  }
}
