import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import type { CustomError } from 'src/app/shared/models/custom-error.interface';
import { AuthenticationService } from '../../shared/services/authentication.service';

export class HttpError extends Error {
  public httpErrorResponse: HttpErrorResponse;

  public constructor(httpErrorResponse: HttpErrorResponse) {
    super(httpErrorResponse.message);
    this.httpErrorResponse = httpErrorResponse;
    this.name = 'HttpError';
  }
}
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly authenticationService = inject(AuthenticationService);

  public token: string = '';

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestWithToken = request.clone({
      headers: request.headers.set('Authorization', this.authenticationService.getTokenValue() || '')
    });
    return next.handle(requestWithToken).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) {
          this.authenticationService.logout();
          throw new Error('Error in TokenInterceptor. ' + err.message.toString());
        }

        if (err.status == 404) {
          throw new HttpError(
            new HttpErrorResponse({
              error: err.error as unknown,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText
            })
          );
        }
        if (err.error != null) {
          const customError = err.error as CustomError;
          const mensagem = String(
            customError.erros['Erro de negócio'] ??
              Object.entries(customError.erros)
                .map(([chave, valor]) => `${chave.charAt(0).toUpperCase() + chave.slice(1)}: ${valor}`)
                .join('\n')
          );
          throw new Error(mensagem);
        }
        throw new Error(err.message);
      })
    );
  }
}
