import type { Routes } from '@angular/router';
import { authGuard } from './area-restrita/core/guards/auth.guard';
import { TituloRotaResolver } from './shared/resolver/titulo-rota.resolver';

export const routes: Routes = [
  {
    path: '',
    title: 'Área Aberta Home',
    loadComponent: async () => import('./area-aberta/features/home/home-area-aberta.component').then((m) => m.HomeAreaAbertaComponent)
  },
  {
    path: 'procurando-saber',
    title: 'Procurando Saber',
    loadComponent: async () => import('./area-aberta/features/procurando-saber/procurando-saber.component').then((m) => m.ProcurandoSaberComponent)
  },
  {
    path: 'institucional/quem-somos',
    title: 'Quem Somos',
    loadComponent: async () => import('./area-aberta/features/institucional/quem-somos/quem-somos.component').then((m) => m.QuemSomosComponent)
  },
  {
    path: 'institucional/gabinete/assessorias',
    title: 'Assessorias',
    loadComponent: async () => import('./area-aberta/features/institucional/gabinete/assessorias/assessorias.component').then((m) => m.AssessoriasComponent)
  },
  {
    path: 'institucional/gabinete/composicao',
    title: 'Composição do Gabinete',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/gabinete/composicao-gabinete/composicao-gabinete.component').then((m) => m.ComposicaoGabineteComponent)
  },
  {
    path: 'institucional/corregedoria/ex-corregedores-gerais',
    title: 'Ex-Corregedores Gerais da PGE',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/corregedoria/ex-corregedores-gerais-pge/ex-corregedores-gerais-pge.component').then(
        (m) => m.ExCorregedoresGeraisPgeComponent
      )
  },
  {
    path: 'institucional/corregedoria/apresentacao-composicao',
    title: 'Apresentação Composição da Corregedoria',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/corregedoria/apresentacao-composicao/apresentacao-composicao.component').then(
        (m) => m.ApresentacaoComposicaoComponent
      )
  },
  {
    path: 'institucional/corregedoria/legislacao',
    title: 'Legislação da Corregedoria',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/corregedoria/legislacao-corregedoria/legislacao-corregedoria.component').then(
        (m) => m.LegislacaoCorregedoriaComponent
      )
  },
  {
    path: 'institucional/conselho/apresentacao',
    title: 'Apresentação do Conselho',
    loadComponent: async () => import('./area-aberta/features/institucional/conselho/apresentacao/apresentacao.component').then((m) => m.ApresentacaoComponent)
  },
  {
    path: 'institucional/conselho/composicao',
    title: 'Composição do Conselho',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/conselho/composicao-conselho/composicao-conselho.component').then((m) => m.ComposicaoConselhoComponent)
  },
  {
    path: 'institucional/conselho/atribuicoes',
    title: 'Atribuições do Conselho',
    loadComponent: async () => import('./area-aberta/features/institucional/conselho/atribuicoes/atribuicoes.component').then((m) => m.AtribuicoesComponent)
  },
  {
    path: 'institucional/conselho/legislacao',
    title: 'Legislação do Conselho',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/conselho/legislacao-conselho/legislacao-conselho.component').then((m) => m.LegislacaoConselhoComponent)
  },
  {
    path: 'institucional/conselho/momento-virtual-procurador',
    title: 'Momento Virtual do Procurador',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/conselho/momento-virtual-procurador/momento-virtual-procurador.component').then(
        (m) => m.MomentoVirtualProcuradorComponent
      )
  },
  {
    path: 'institucional/consultoria-geral/autarquias',
    title: 'Autarquias da Consultoria Geral',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/consultoria-geral/autarquias/autarquias.component').then((m) => m.AutarquiasComponent)
  },
  {
    path: 'institucional/consultoria-geral/especializadas',
    title: 'Consultoria Geral - Especializadas',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/consultoria-geral/especializadas-consultoria-geral/especializadas-consultoria-geral.component').then(
        (m) => m.EspecializadasConsultoriaGeralComponent
      )
  },
  {
    path: 'institucional/consultoria-geral/consultorias',
    title: 'Consultoria Geral - Consultorias',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/consultoria-geral/consultorias/consultorias.component').then((m) => m.ConsultoriasComponent)
  },
  {
    path: 'institucional/consultoria-geral/subprocuradoria',
    title: 'Consultoria Geral - Subprocuradoria',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/consultoria-geral/subprocuradoria-consultoria-geral/subprocuradoria-consultoria-geral.component').then(
        (m) => m.SubprocuradoriaConsultoriaGeralComponent
      )
  },
  {
    path: 'institucional/contencioso-geral/subprocuradoria',
    title: 'Contencioso Geral - Subprocuradoria',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/contencioso-geral/subprocuradoria-contencioso-geral/subprocuradoria-contencioso-geral.component').then(
        (m) => m.SubprocuradoriaContenciosoGeralComponent
      )
  },
  {
    path: 'institucional/contencioso-geral/especializadas',
    title: 'Contencioso Geral - Especializadas',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/contencioso-geral/especializadas-contencioso-geral/especializadas-contencioso-geral.component').then(
        (m) => m.EspecializadasContenciosoGeralComponent
      )
  },
  {
    path: 'institucional/contencioso-geral/regionais',
    title: 'Contencioso Geral - Regionais',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/contencioso-geral/regionais-contencioso-geral/regionais-contencioso-geral.component').then(
        (m) => m.RegionaisContenciosoGeralComponent
      )
  },
  {
    path: 'institucional/regionais/PR01',
    title: 'Regionais - PR01',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr01/pr01.component').then((m) => m.PR01Component)
  },
  {
    path: 'institucional/regionais/PR02',
    title: 'Regionais - PR02',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr02/pr02.component').then((m) => m.PR02Component)
  },
  {
    path: 'institucional/regionais/PR03',
    title: 'Regionais - PR03',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr03/pr03.component').then((m) => m.PR03Component)
  },
  {
    path: 'institucional/regionais/PR04',
    title: 'Regionais - PR04',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr04/pr04.component').then((m) => m.PR04Component)
  },
  {
    path: 'institucional/regionais/PR05',
    title: 'Regionais - PR05',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr05/pr05.component').then((m) => m.PR05Component)
  },
  {
    path: 'institucional/regionais/PR06',
    title: 'Regionais - PR06',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr06/pr06.component').then((m) => m.PR06Component)
  },
  {
    path: 'institucional/regionais/PR07',
    title: 'Regionais - PR07',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr07/pr07.component').then((m) => m.PR07Component)
  },
  {
    path: 'institucional/regionais/PR08',
    title: 'Regionais - PR08',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr08/pr08.component').then((m) => m.PR08Component)
  },
  {
    path: 'institucional/regionais/PR09',
    title: 'Regionais - PR09',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr09/pr09.component').then((m) => m.PR09Component)
  },
  {
    path: 'institucional/regionais/PR10',
    title: 'Regionais - PR10',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr10/pr10.component').then((m) => m.PR10Component)
  },
  {
    path: 'institucional/regionais/PR11',
    title: 'Regionais - PR11',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr11/pr11.component').then((m) => m.PR11Component)
  },
  {
    path: 'institucional/regionais/PR12',
    title: 'Regionais - PR12',
    loadComponent: async () => import('./area-aberta/features/institucional/regionais/pr12/pr12.component').then((m) => m.PR12Component)
  },
  {
    path: 'institucional/tributario-fiscal/subprocuradoria',
    title: 'Tributário Fiscal - Subprocuradoria',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/tributario-fiscal/subprocuradoria-tributario-fiscal/subprocuradoria-tributario-fiscal.component').then(
        (m) => m.SubprocuradoriaTributarioFiscalComponent
      )
  },
  {
    path: 'institucional/tributario-fiscal/especializadas',
    title: 'Tributário Fiscal - Especializadas',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/tributario-fiscal/especializadas-tributario-fiscal/especializadas-tributario-fiscal.component').then(
        (m) => m.EspecializadasTributarioFiscalComponent
      )
  },
  {
    path: 'institucional/tributario-fiscal/regionais',
    title: 'Tributário Fiscal - Regionais',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/tributario-fiscal/regionais-tributario-fiscal/regionais-tributario-fiscal.component').then(
        (m) => m.RegionaisTributarioFiscalComponent
      )
  },
  {
    path: 'institucional/procuradorias-regionais',
    title: 'Procuradorias Regionais',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/procuradorias-regionais/procuradorias-regionais.component').then((m) => m.ProcuradoriasRegionaisComponent)
  },
  {
    path: 'institucional/historico',
    title: 'Histórico da PGE',
    loadComponent: async () => import('./area-aberta/features/institucional/historico/historico.component').then((m) => m.HistoricoComponent)
  },
  {
    path: 'institucional/ex-procuradores-gerais',
    title: 'Ex-procuradores Gerais',
    loadComponent: async () =>
      import('./area-aberta/features/institucional/ex-procuradores-gerais/ex-procuradores-gerais.component').then((m) => m.ExProcuradoresGeraisComponent)
  },
  {
    path: 'institucional/legislacao-pesquisa',
    title: 'Pesquisa Legislação',
    loadComponent: async () => import('./area-aberta/features/institucional/legislacao/legislacao.component').then((m) => m.LegislacaoComponent)
  },
  {
    path: 'servicos/portal-precatorios',
    title: 'Portal de Precatórios',
    loadComponent: async () =>
      import('./area-aberta/features/servicos/portal-precatorios/portal-precatorios.component').then((m) => m.PortalPrecatoriosComponent)
  },
  {
    path: 'servicos/portal-precatorios/perguntas-frequentes',
    title: 'Portal de Precatórios - Perguntas Frequentes',
    loadComponent: async () =>
      import('./area-aberta/features/servicos/portal-precatorios/perguntas-frequentes/perguntas-frequentes.component').then(
        (m) => m.PerguntasFrequentesComponent
      )
  },
  {
    path: 'servicos/reparacao-danos',
    title: 'Reparação de Danos',
    loadComponent: async () => import('./area-aberta/features/servicos/reparacao-danos/reparacao-danos.component').then((m) => m.ReparacaoDanosComponent)
  },
  {
    path: 'servicos/atendimento-cidadao',
    title: 'Atendimento ao Cidadão',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/enderecos-telefones-uteis/enderecos-telefones-uteis.component').then(
        (m) => m.EnderecosTelefonesUteisComponent
      )
  },
  {
    path: 'ouvidoria/quem-somos',
    title: 'Ouvidoria - Quem Somos',
    loadComponent: async () =>
      import('./area-aberta/features/ouvidoria/quem-somos-ouvidoria/quem-somos-ouvidoria.component').then((m) => m.QuemSomosOuvidoriaComponent)
  },
  {
    path: 'ouvidoria/atendimento',
    title: 'Ouvidoria - Atendimento',
    loadComponent: async () => import('./area-aberta/features/ouvidoria/atendimento/atendimento.component').then((m) => m.AtendimentoComponent)
  },
  {
    path: 'ouvidoria/relatorios',
    title: 'Ouvidoria - Relatórios',
    loadComponent: async () => import('./area-aberta/features/ouvidoria/relatorios/relatorios.component').then((m) => m.RelatoriosComponent)
  },
  {
    path: 'ouvidoria/sancoes',
    title: 'Ouvidoria - Sanções',
    loadComponent: async () => import('./area-aberta/features/ouvidoria/consulta-sancoes/consulta-sancoes.component').then((m) => m.ConsultaSancoesComponent)
  },
  {
    path: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
    title: 'Atendimento Sobre Protesto/ICMS/IPVA/ITCMD/Certidão Negativa',
    loadComponent: async () =>
      import(
        './area-aberta/features/informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa/atendimento-protestos-icms-ipva-itcmd-certidao-negativa.component'
      ).then((m) => m.AtendimentoProtestosIcmsIpvaItcmdCertidaoNegativaComponent)
  },
  {
    path: 'informacao-cidadao/diario-oficial',
    title: 'Diário Oficial',
    loadComponent: async () => import('./area-aberta/features/informacao-cidadao/diario-oficial/diario-oficial.component').then((m) => m.DiarioOficialComponent)
  },
  {
    path: 'informacao-cidadao/cepen',
    title: 'CEPEN',
    loadComponent: async () => import('./area-aberta/features/informacao-cidadao/cepen/cepen.component').then((m) => m.CepenComponent)
  },
  {
    path: 'informacao-cidadao/comunicados',
    title: 'Comunicados',
    loadComponent: async () => import('./area-aberta/features/informacao-cidadao/comunicados/comunicados.component').then((m) => m.ComunicadosComponent)
  },
  {
    path: 'informacao-cidadao/comunicado/:id',
    title: 'Visualização de Comunicado',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/visualizar-comunicado/visualizar-comunicado.component').then((m) => m.VisualizarComunicadoComponent)
  },
  {
    path: 'informacao-cidadao/enderecos-telefones-uteis',
    title: 'Endereços e Telefones Úteis',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/enderecos-telefones-uteis/enderecos-telefones-uteis.component').then(
        (m) => m.EnderecosTelefonesUteisComponent
      )
  },
  {
    path: 'informacao-cidadao/localizar-regionais',
    title: 'Localizar Regionais',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/localizar-regionais/localizar-regionais.component').then((m) => m.LocalizarRegionaisComponent)
  },
  {
    path: 'informacao-cidadao/credenciamento-contadores',
    title: 'Credenciamento de Contadores',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/credenciamento-contadores/credenciamento-contadores.component').then(
        (m) => m.CredenciamentoContadoresComponent
      )
  },
  {
    path: 'informacao-cidadao/credenciamento-contadores/noticia/:id',
    title: 'Credenciamento de Contadores - Visualização de Notícia',
    loadComponent: async () =>
      import('./area-aberta/features/informacao-cidadao/credenciamento-contadores/noticia-contadores/noticia-contadores.component').then(
        (m) => m.NoticiaContadoresComponent
      )
  },
  {
    path: 'programa-estagio',
    title: 'Programa de Estágio',
    loadComponent: async () => import('./area-aberta/features/programa-estagio/programa-estagio.component').then((m) => m.ProgramaEstagioComponent)
  },
  {
    path: 'planejamento-estrategico',
    title: 'Planejamento Estratégico',
    loadComponent: async () =>
      import('./area-aberta/features/planejamento-estrategico/planejamento-estrategico.component').then((m) => m.PlanejamentoEstrategicoComponent)
  },
  {
    path: 'minutas-padronizadas',
    title: 'Minutas Padronizadas',
    loadComponent: async () => import('./area-aberta/features/minutas-padronizadas/minutas-padronizadas.component').then((m) => m.MinutasPadronizadasComponent)
  },
  {
    path: 'servicos/divida-ativa',
    title: 'Dívida Ativa',
    loadComponent: async () => import('./area-aberta/features/servicos/divida-ativa/divida-ativa.component').then((m) => m.DividaAtivaComponent)
  },
  {
    path: 'noticias-diversas',
    title: 'Notícias Diversas',
    loadComponent: async () =>
      import('./area-aberta/features/home/noticias/noticias-diversas/noticias-diversas.component').then((m) => m.NoticiasDiversasComponent),
    pathMatch: 'full'
  },
  {
    path: 'uma-noticia/:id',
    title: 'Visualização de Notícia',
    loadComponent: async () => import('./area-aberta/features/home/noticias/uma-noticia/uma-noticia.component').then((m) => m.UmaNoticiaComponent)
  },
  {
    path: 'login',
    title: 'Login',
    loadComponent: async () => import('./area-restrita/features/login/login-card/login-card.component').then((m) => m.LoginCardComponent),
    canActivate: [authGuard]
  },
  {
    path: 'esqueci-senha',
    title: 'Esqueci a Senha',
    loadComponent: async () => import('./area-restrita/features/login/esqueci-senha-card/esqueci-senha-card.component').then((m) => m.EsqueciSenhaCardComponent)
  },
  {
    path: 'alterar-senha',
    title: 'Alterar Senha',
    loadComponent: async () => import('./area-restrita/features/login/alterar-senha-card/alterar-senha-card.component').then((m) => m.AlterarSenhaCardComponent)
  },
  {
    path: 'menu/:opcao',
    title: TituloRotaResolver,
    loadComponent: async () => import('./area-aberta/features/submenu/submenu.component').then((m) => m.SubmenuComponent)
  },
  {
    path: 'comunicar-erro',
    title: 'Comunicar Erro',
    loadComponent: async () => import('./area-aberta/features/comunicar-erro/comunicar-erro.component').then((m) => m.ComunicarErroComponent)
  },
  {
    path: 'lgpd',
    title: 'LGPD',
    loadComponent: async () =>
      import('./area-aberta/features/privacidade-protecao-dados/privacidade-protecao-dados.component').then((m) => m.PrivacidadeProtecaoDadosComponent)
  },
  {
    path: 'area-restrita/contencioso-tributario-fiscal/portal-tributario-fiscal',
    title: 'Contencioso Tributário Fiscal - Portal Tributário Fiscal',
    loadComponent: async () =>
      import('./area-restrita/features/legado/portal-tributario-fiscal/portal-tributario-fiscal.component').then((m) => m.PortalTributarioFiscalComponent),
    canActivate: [authGuard]
  },
  {
    path: 'area-restrita',
    loadComponent: async () => import('./area-restrita/core/layout/home-area-restrita/home-area-restrita.component').then((m) => m.HomeAreaRestritaComponent),
    canActivate: [authGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: 'home',
        title: 'Home - Area Restrita',
        loadComponent: async () =>
          import('./area-restrita/features/home/home-info-area-restrita/home-info-area-restrita.component').then((m) => m.HomeInfoAreaRestritaComponent)
      },
      {
        path: 'logout-site-pge',
        title: 'Logout',
        loadComponent: async () => import('./area-restrita/features/logout/logout.component').then((m) => m.LogoutComponent)
      },
      {
        path: 'noticia-home/:id',
        title: 'Visualizar Notícia Área Restrita',
        loadComponent: async () => import('./area-restrita/features/home/home-noticia/home-noticia.component').then((m) => m.HomeNoticiaComponent)
      },
      {
        path: 'administracao-auxilio-saude',
        children: [
          {
            path: 'analisar-pedido',
            title: 'Auxílio Saúde - Analisar Pedido',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/mesa-analise-pedido-auxilio-saude/mesa-analise-pedido-auxilio-saude.component').then(
                (m) => m.MesaAnalisePedidoAuxilioSaudeComponent
              )
          },
          {
            path: 'analisar-inscricao',
            title: 'Auxílio Saúde - Analisar Inscrição',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/mesa-analise-inscricao/mesa-analise-inscricao.component').then(
                (m) => m.MesaAnaliseInscricaoComponent
              )
          },
          {
            path: 'financeiro',
            title: 'Auxílio Saúde - Financeiro',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/auxilio-financeiro/auxilio-financeiro.component').then((m) => m.AuxilioFinanceiroComponent)
          },
          {
            path: 'relatorio',
            title: 'Auxílio Saúde - Relatório',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/relatorio-auxilio-saude/relatorio-auxilio-saude.component').then(
                (m) => m.RelatorioAuxilioSaudeComponent
              )
          },
          {
            path: 'limite-mensal',
            title: 'Auxílio Saúde - Limite Mensal',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/limite-mensal-auxilio-saude/limite-mensal-auxilio-saude.component').then(
                (m) => m.LimiteMensalAuxilioSaudeComponent
              )
          }
        ]
      },
      {
        path: 'administracao-noticia',
        children: [
          {
            path: 'cadastrar-noticia',
            title: 'Cadastrar Notícia',
            loadComponent: async () =>
              import('./area-restrita/features/noticia/consultar-noticias/consultar-noticias.component').then((m) => m.ConsultarNoticiasComponent)
          }
        ]
      },
      {
        path: 'auxilio-saude',
        children: [
          {
            path: 'solicitacao-reembolso',
            title: 'Auxílio Saúde - Solicitação de Reembolso',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/solicitacao-auxilio-saude/solicitacao-auxilio-saude.component').then(
                (m) => m.SolicitacaoAuxilioSaudeComponent
              )
          },
          {
            path: 'ficha-inscricao',
            title: 'Auxílio Saúde - Ficha de Inscriação',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/ficha-auxilio-saude/ficha-auxilio-saude.component').then((m) => m.FichaAuxilioSaudeComponent)
          },
          {
            path: 'dados-pedido',
            title: 'Auxílio Saúde - Consulta de Pedidos',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/saude/pedido-auxilio-saude/pedido-auxilio-saude.component').then((m) => m.PedidoAuxilioSaudeComponent)
          }
        ]
      },
      {
        path: 'auxilio-oab',
        children: [
          {
            path: 'solicitacao-reembolso',
            title: 'Auxílio OAB - Solicitação de Reembolso',
            loadComponent: async () =>
              import('./area-restrita/features/auxilio/oab/solicitacao-reembolso-oab/solicitacao-reembolso-oab.component').then(
                (m) => m.SolicitacaoReembolsoOabComponent
              )
          }
        ]
      },
      {
        path: 'analise-pedido-auxilio-saude/:id',
        title: 'Auxílio Saúde - Visualização do Pedido',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/analise-pedido-auxilio-saude/analise-pedido-auxilio-saude.component').then(
            (m) => m.AnalisePedidoAuxilioSaudeComponent
          )
      },
      {
        path: 'inscricao-auxilio-avaliacao/:id',
        title: 'Auxílio Saúde - Avaliação de Inscrição',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/ficha-auxilio-saude/ficha-auxilio-saude.component').then((m) => m.FichaAuxilioSaudeComponent)
      },
      {
        path: 'auxilio-financeiro',
        title: 'Auxílio Financeiro',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/auxilio-financeiro/auxilio-financeiro.component').then((m) => m.AuxilioFinanceiroComponent)
      },
      {
        path: 'siafem-importe',
        title: 'Auxílio Saúde - SIAFEM',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/siafem-importe/siafem-importe.component').then((m) => m.SiafemImporteComponent)
      },
      {
        path: 'criar-noticia',
        title: 'Criar Notícia',
        loadComponent: async () => import('./area-restrita/features/noticia/criar-noticia/criar-noticia.component').then((m) => m.CriarNoticiaComponent)
      },
      {
        path: 'editar-noticia/:id',
        title: 'Editar Notícia',
        loadComponent: async () => import('./area-restrita/features/noticia/editar-noticia/editar-noticia.component').then((m) => m.EditarNoticiaComponent)
      },
      {
        path: 'visualizar-noticia/:id',
        title: 'Visualizar Notícia',
        loadComponent: async () =>
          import('./area-restrita/features/noticia/visualizar-noticia/visualizar-noticia.component').then((m) => m.VisualizarNoticiaComponent)
      },
      {
        path: 'analisar-inscricao/:id',
        title: 'Auxílio Saúde - Analisar Inscrição',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/ficha-analise-inscricao/ficha-analise-inscricao.component').then(
            (m) => m.FichaAnaliseInscricaoComponent
          )
      },
      {
        path: 'pedido-auxilio-saude/:id',
        title: 'Auxílio Saúde - Visualizar Pedido',
        loadComponent: async () =>
          import('./area-restrita/features/auxilio/saude/solicitacao-auxilio-saude/solicitacao-auxilio-saude.component').then(
            (m) => m.SolicitacaoAuxilioSaudeComponent
          )
      },
      {
        path: 'alterar-senha-area-restrita',
        title: 'Alterar Senha da Área Restrita',
        loadComponent: async () =>
          import('./area-restrita/features/login/alterar-senha-card/alterar-senha-card.component').then((m) => m.AlterarSenhaCardComponent)
      },
      { path: '**', title: 'Redirecionamento para home por rota não encontrada', redirectTo: 'home' }
    ]
  }
];
