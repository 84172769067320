import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { servicoList } from 'src/app/area-aberta/features/home/servicos/servicos';
import { menuList } from '../../menu/menu-file';

@Component({
  selector: 'app-mapa-site',
  templateUrl: './mapa-site.component.html',
  styleUrl: './mapa-site.component.scss',
  imports: [MatIcon, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapaSiteComponent {
  public menuList = menuList;
  public servicoList = servicoList;

  public espandida: boolean = false;

  public alteranarConteudo(): void {
    this.espandida = !this.espandida;
  }
}
