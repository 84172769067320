import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatLine } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatList, MatListItem } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { acessoRapido } from 'src/app/area-aberta/features/home/acesso-rapido/acesso-rapido';
import type { Servico } from 'src/app/area-aberta/features/home/servicos/servico.model';
import { servicoList } from 'src/app/area-aberta/features/home/servicos/servicos';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { menuList } from '../menu-file';

interface SearchItem {
  titulo: string;
  url: string;
  texto?: string;
  dataPublicacao?: string;
  redirecionar?: string;
  externo?: boolean;
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  imports: [MatList, MatListItem, MatLine, RouterLink, MatDivider, MatProgressSpinner],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly noticiaService = inject(NoticiaService);

  public readonly buscar = signal('');
  public readonly carregando = signal(false);

  public readonly resultadoBusca = signal<SearchItem[]>([]);

  public ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.buscar.set(params['buscar'] as string);
      this.noticiaPesquisa();
    });
  }

  public ajusteHTML(fonte: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return (tmp.textContent ?? tmp.innerText).slice(0, 350);
  }

  private noticiaPesquisa(): void {
    this.carregando.set(true);
    this.noticiaService.getNoticiaByName(this.buscar()).subscribe({
      next: (resp) => {
        const results = resp.map((noticia) => {
          return { ...noticia, url: `uma-noticia/${noticia.id}` };
        });
        this.resultadoBusca.set([...results, ...this.buscarServicos(), ...this.buscarPaginasInternas(), ...this.buscarAcessoRapido()]);

        this.carregando.set(false);
      },
      error: (error) => {
        console.error('Erro em buscar dados ', error);
        this.carregando.set(false);
        this.resultadoBusca.set([...this.resultadoBusca(), ...this.buscarServicos(), ...this.buscarPaginasInternas(), ...this.buscarAcessoRapido()]);
      }
    });
  }

  private buscarServicos(): Servico[] {
    return servicoList.filter((servico) => servico.titulo.toLowerCase().includes(this.buscar().toLowerCase()));
  }

  private buscarAcessoRapido(): Servico[] {
    return acessoRapido
      .map((acesso) => {
        return {
          titulo: acesso.alt,
          url: acesso.redirecionar
        };
      })
      .filter(({ titulo }) => titulo.toLowerCase().includes(this.buscar().toLowerCase()));
  }

  private buscarPaginasInternas(): Servico[] {
    let todasPaginas: Servico[] = [];

    menuList.forEach((item) => {
      if (item.menuFilho) {
        const servicos: Servico[] = item.menuFilho.map((menu) => {
          return {
            titulo: menu.titulo,
            url: menu.redirecionar ?? ''
          };
        });
        todasPaginas = todasPaginas.concat(servicos);
        return item;
      }

      const servico: Servico = {
        titulo: item.titulo,
        url: item.redirecionar ?? ''
      };

      todasPaginas.push(servico);
      return item;
    });

    return todasPaginas.flat(1).filter(({ titulo }) => String(titulo).toLowerCase().includes(this.buscar().toLowerCase()));
  }
}
