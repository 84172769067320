import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  imports: [MatFormField, MatInput, FormsModule, ReactiveFormsModule, MatButton, MatSuffix, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);

  public buscarControl = new FormControl('');

  public ngOnInit(): void {
    this.obterConsultaParametro();
  }

  public buscar(): void {
    const value = this.buscarControl.value;
    const queryParam = this.activeRoute.snapshot.queryParams['buscar'] as string;

    if (value == undefined && queryParam) {
      void this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    if (value == undefined) return;

    void this.router.navigate(['/'], { queryParams: { buscar: value } }).then(() => {
      window.location.reload();
    });
  }

  private obterConsultaParametro(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.buscarControl.setValue(params['buscar'] as string);
    });
  }
}
