import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [MatIcon, MatTooltip],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  private readonly router = inject(Router);

  public tamanhoFonte = 16;
  public readonly tema = signal<string>('claro');

  public constructor() {
    this.tema.set(localStorage.getItem('tema') ?? 'claro');
    if (this.tema() == 'escuro') document.body.classList.add('dark-theme');

    this.tamanhoFonte = Number(localStorage.getItem('fonte')) || 16;
    this.alterarSalvarFonte();
  }

  public paginaInicial(): void {
    void this.router.navigate(['/']);
  }

  public incrementarFonte(): void {
    if (this.tamanhoFonte >= 30) return;
    this.tamanhoFonte += 1;
    this.alterarSalvarFonte();
  }

  public decrementarFonte(): void {
    if (this.tamanhoFonte <= 10) return;
    this.tamanhoFonte -= 1;
    this.alterarSalvarFonte();
  }

  public alterarSalvarFonte(): void {
    document.getElementsByTagName('html')[0].style.fontSize = this.tamanhoFonte + 'px';
    localStorage.setItem('fonte', this.tamanhoFonte.toString());
  }

  public alterarTema(): void {
    this.tema.set(this.tema() == 'claro' ? 'escuro' : 'claro');
    document.body.classList.toggle('dark-theme');
    localStorage.setItem('tema', this.tema());
  }
}
