import type { MenuNode } from './MenuNode';

export const menuPgeFim: MenuNode = {
  id: 0,
  nome: 'Sair',
  descricao: 'Sair da página - Logout',
  rota: 'logout-site-pge',
  url: 'S',
  ordem: 0,
  filhos: [],
  materialIcon: 'logout'
};
