import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuItem } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { menuList } from './menu-file';
import type { MenuItem } from './menu.model';
import { SearchComponent } from './search/search.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  imports: [MatIconButton, MatDialogClose, MatIcon, MatMenuItem, SearchComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  /* *** Testes do LOGIN ÚNICO *** TEMPORÁRIO ATÉ O FINAL DOS TESTES ***
  TODO - security, parameter
  */
  public routerDefault: string = 'http://www.portal-legado.hom.pge.sp.gov.br/SitePge/Login.aspx?ReturnUrl=%2fSitePge%2frestrito%2fdefault.aspx';
  public ambiente = environment.env;
  public subMenuitem: MenuItem | null;
  public menuList: MenuItem[];
  public busca: string;

  public constructor() {
    this.menuList = menuList;
  }
  public ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.busca = params['search'] as string;
    });
  }

  public alternarMenu(menuItem: MenuItem): void {
    if (this.subMenuitem === menuItem) {
      this.subMenuitem = null;
    } else {
      this.subMenuitem = menuItem;
    }
  }

  public paginaInicial(): void {
    void this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  public redirecionar(menu: MenuItem): void {
    if (menu.externo !== undefined && !menu.externo) {
      void this.router.navigate([menu.redirecionar]);
    } else {
      window.open(menu.redirecionar, '_blank');
    }
  }
}
