import { ChangeDetectionStrategy, Component } from '@angular/core';
import { globais } from 'src/app/area-restrita/shared/globais/globais';
import { MapaSiteComponent } from './mapa-site/mapa-site.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [MapaSiteComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public readonly versao = globais.versionFront;
}
