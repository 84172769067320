import type { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { globais } from 'src/app/area-restrita/shared/globais/globais';
import type { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  private readonly token: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('token') ?? '');

  public setToken(token: string | null): void {
    if (token != null) {
      this.token.next(token);
      globais.tokenBearer = token;
      localStorage.setItem('token', token);
    }
  }

  public setIdUsuario(idUsuario: number): void {
    globais.idUsuario = idUsuario;
    localStorage.setItem('idUsuario', idUsuario.toString());
  }

  public login(usuario: string, senha: string): Observable<HttpResponse<UsuarioLogin>> {
    localStorage.setItem('usuario', usuario.trim());
    localStorage.setItem('senha', senha.trim());
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('usuario', usuario).set('senha', senha);

    return this.http.post<UsuarioLogin>(`${globais.urlToken}site-pge/api/login`, body.toString(), { headers: headers, observe: 'response' });
  }

  public logout(route: string = '/', logoutLegado: boolean = true): void {
    this.token.next('');
    const usuarioLegado = localStorage.getItem('usuarioLegado') == '1';
    globais.idUsuario = 0;
    globais.tokenBearer = '';
    globais.isLogin = false;
    localStorage.clear();
    if (usuarioLegado && logoutLegado) {
      const legadoLogout = environment.apiAreaRestritaLegado.replace('default.aspx', 'Logout.aspx?CodMenu=292');
      window.location.href = legadoLogout;
    } else {
      void this.router.navigate([route]);
    }
  }

  public getTokenValue(): string {
    return this.token.value;
  }
}
