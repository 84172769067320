import { inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  public inicializar(): void {
    const gaId = environment.googleAnalyticsId;

    if (gaId == '') {
      console.warn('Google Analytics ID não informado');
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const gtagEl = document.createElement('script');
    const gtagBody = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
    gtagEl.appendChild(gtagBody);
    document.body.appendChild(gtagEl);

    window.gtag('js', new Date());
    window.gtag('config', gaId);

    this.rastrearVisualizacaoPaginas();
  }

  private rastrearVisualizacaoPaginas(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      window.gtag('event', 'page_view', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        page_path: event.urlAfterRedirects,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        page_title: this.getRouteTitle(this.activatedRoute.root)
      });
    });
  }

  private getRouteTitle(route: ActivatedRoute): string {
    let child = route.firstChild;
    let title: string | null = null;

    while (child) {
      const data = child.snapshot.data;
      const symbols = Object.getOwnPropertySymbols(data);

      for (const symbol of symbols) {
        if (symbol.toString() === 'Symbol(RouteTitle)') {
          title = data[symbol] as string;
          break;
        }
      }

      if (title != null) break;

      child = child.firstChild;
    }

    return title ?? 'Página sem título'; // Retorna o título ou um padrão
  }
}
